import React from "react";
import {
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Typography,
	Divider,
} from "@mui/material";

function Facets({ items, onSectionChange }) {
	const handleChange = (event, section, item) => {
		onSectionChange(section, item, event.target.checked);
	};

	function prettyPrint(sectionName) {
		const tokens = sectionName.replace("_", " ").split(" ");
		const uppercase = tokens.map(
			(word) => word[0].toUpperCase() + word.substring(1)
		);
		return uppercase.join(" ");
	}

	return (
		<FormControl component="fieldset">
			{items.map(([sectionName, items]) => (
				<React.Fragment key={sectionName}>
					<Typography
						variant="h6"
						sx={{
							paddingLeft: "15px",
							paddingTop: "10px",
						}}
					>
						{prettyPrint(sectionName)}
					</Typography>
					<FormGroup>
						{items.map((item) => (
							<FormControlLabel
								key={item[0]}
								sx={{ alignItems: "flex-start" }}
								control={
									<div sx={{ display: "flex", alignItems: "flex-start" }}>
										<Checkbox
											checked={item[2]}
											onChange={(e) => handleChange(e, sectionName, item[0])}
											sx={{
												marginTop: -1,
												marginLeft: "30px",
											}}
										/>
									</div>
								}
								label={`${item[0]} (${item[1]})`}
							/>
						))}
					</FormGroup>
					<Divider
						variant="middle"
						sx={{
							paddingTop: "15px",
						}}
					/>
				</React.Fragment>
			))}
		</FormControl>
	);
}

export default Facets;
