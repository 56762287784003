import React, { useState } from "react";
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
} from "@mui/material";

export default function LoginDialog({ onSubmit }) {
	const [open, setOpen] = useState(false);
	const [pswd, setPswd] = useState("");

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	function handleSubmit(event) {
		event.preventDefault();
		setOpen(false);
		onSubmit(pswd);
	}

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				Login
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogContent>
					<form onSubmit={handleSubmit}>
						<TextField
							autoFocus
							margin="dense"
							id="pswd"
							label="Enter password"
							type="password"
							fullWidth
							variant="standard"
							onChange={(event) => setPswd(event.target.value)}
						/>
						<DialogActions>
							<Button type="submit">Login</Button>
						</DialogActions>
					</form>
				</DialogContent>
			</Dialog>
		</div>
	);
}
