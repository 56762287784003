import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Chip, Link, CardHeader } from "@mui/material";

export default function DataSetCard({ dataset, onClick }) {
	return (
		<Card
			sx={{ maxWidth: 800, margin: "15px" }}
			onClick={() => onClick(dataset)}
		>
			<CardActionArea>
				<CardHeader
					title={dataset.name}
					subheader={`${dataset.provider} (#${dataset.row})`}
				/>
				<CardContent>
					<Typography variant="body2" color="text.secondary">
						{dataset.description}
					</Typography>
					<Typography>
						<Link href={dataset.link} target="_blank" rel="noreferrer">
							{dataset.link}
						</Link>
					</Typography>

					<Chip
						label={dataset.type}
						sx={{ marginRight: "5px", marginTop: "5px" }}
					/>
					<Chip
						label={dataset.classification}
						sx={{ marginRight: "5px", marginTop: "5px" }}
					/>
					<Chip
						label={dataset.ecosystem_service}
						sx={{ marginRight: "5px", marginTop: "5px" }}
					/>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
