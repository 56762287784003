import React from "react";
import { Paper, InputBase, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function SearchBox({ onSubmit }) {
	const [value, setValue] = React.useState("");

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		onSubmit(value);
	};

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				alignItems: "center",
				width: 800,
				height: 30,
				marginLeft: "15px",
			}}
			elevation={1}
		>
			<InputBase
				sx={{
					m: 1,
					flex: 1,
				}}
				placeholder="Search"
				value={value}
				onChange={handleChange}
				onKeyPress={(e) => e.key === "Enter" && handleSubmit(e)}
			/>
			<IconButton
				sx={{
					p: 1,
				}}
				aria-label="Search"
				onClick={handleSubmit}
			>
				<SearchIcon />
			</IconButton>
		</Paper>
	);
}

export default SearchBox;
