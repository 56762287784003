import React, { useState, useEffect, useRef } from "react";

import {
	Drawer,
	Box,
	CircularProgress,
	CssBaseline,
	AppBar,
	Toolbar,
	Divider,
} from "@mui/material";

import Favicon from "react-favicon";
import { Virtuoso as VirtualizedList } from "react-virtuoso";

import Facets from "./Facets";
import DataSetCard from "./DataSetCard";
import SearchBox from "./SearchBox";
import DataSetDialog from "./DataSetDialog";
import LoginDialog from "./LoginDialog";

import CryptoJS from "crypto-js";

const APP_TITLE = "Global Data Inventory";
const G4N_API = "https://search-api-func-i3royjca7a-uc.a.run.app";
// const G4N_API = "http://localhost:8080";
const FACETS = ["type", "classification", "ecosystem_service"];
const PSWD = [
	"51a8368ad713be6036b046fa2976e5c14a13c8f8e740dac0f544f1943956a1659e39cbcf3f8843b29eda15ea5f02704fe4869a32403b609a14e9379e9d6a2e87",
];
const LOGO1 = "images/TNFD-logo-blue.png";
// const LOGO2 = "images/mrv-collective-logo-circle.png";
const LOGO2 = "images/ntc_logo_standard_dark.png";

function pswdGood(hashedPswd) {
	console.log("PASSWORD CHECK", hashedPswd);
	return PSWD.includes(hashedPswd);
}

function App() {
	const [isLoggedIn, setIsLoggedIn] = React.useState(
		() =>
			window.location.pathname !== "/login" &&
			pswdGood(localStorage.getItem("pswd"))
	);

	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	const [facetSelections, setFacetSelections] = useState();
	const [facetCounts, setFacetCounts] = useState();
	const [orderedFacets, setOrderedFacets] = useState();

	const [isLoading, setIsLoading] = useState(false);
	const [dialogData, setDialogData] = useState();

	const listRef = useRef(null);

	useEffect(() => {
		document.title = APP_TITLE;
	}, []);

	// Outputs:[ [sectionA, [[item1, count, selected], ...]], ... ]
	useEffect(
		function orderFacets() {
			if (!facetCounts) {
				return;
			}
			const ordered = [];
			for (const section of FACETS) {
				const itemOrder = Object.keys(facetCounts[section]).sort();
				const orderedItems = [];
				for (const item of itemOrder) {
					orderedItems.push([
						item,
						facetCounts[section][item],
						facetSelections ? facetSelections[section][item] : false,
					]);
				}
				ordered.push([section, orderedItems]);
			}
			setOrderedFacets(ordered);
		},
		[facetCounts, facetSelections]
	);

	useEffect(
		function resetScroll() {
			if (searchResults.length) {
				listRef.current.scrollToIndex({ index: 0 });
			}
		},
		[searchResults]
	);

	useEffect(
		function executeSearch() {
			if (!isLoggedIn) {
				return;
			}

			setIsLoading(true);

			const url = `${G4N_API}/search?q=${searchQuery}&${new URLSearchParams(
				getChecked(facetSelections)
			)}`;
			console.log("SEARCHING...", url);
			console.time("search took");

			fetch(url, {
				method: "GET",
				headers: { "Content-Type": "application/json" },
			})
				.then((res) => res.json())
				.then((data) => {
					console.timeEnd("search took");
					setSearchResults(data?.results);
					setFacetCounts(data?.facets);
					setIsLoading(false);
				});
		},
		[isLoggedIn, facetSelections, searchQuery]
	);

	const handleSearchBoxSubmit = (searchQuery) => {
		setSearchQuery(searchQuery);
		setFacetSelections(null);
	};

	const handleFacetChange = (section, item, value) => {
		let newFacets;
		if (facetSelections) {
			newFacets = { ...facetSelections };
		} else {
			// Initialize Facets
			newFacets = {};
			for (const facet of FACETS) {
				newFacets[facet] = {};
				for (const item of Object.keys(facetCounts[facet])) {
					newFacets[facet][item] = false;
				}
			}
		}
		newFacets[section][item] = value;
		setFacetSelections(newFacets);
	};

	const onDataSetClick = (dataset) => {
		setDialogData(dataset);
	};

	const onPasswordSubmit = async (pswd) => {
		console.log(`PASSWORD SUBMIT: ${pswd.length} chars`);

		// hash the password
		const hashedPswd = CryptoJS.SHA512(pswd).toString();

		// Check the password
		if (pswdGood(hashedPswd)) {
			localStorage.setItem("pswd", hashedPswd);
			window.history.replaceState(null, "APP_TITLE", "/");
			setIsLoggedIn(true);
		} else {
			localStorage.setItem("pswd", "");
			setIsLoggedIn(false);
			alert("Incorrect password");
		}
	};

	const drawerWidth = 240;

	return (
		<>
			<Favicon url={LOGO1} />
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<AppBar
					position="fixed"
					sx={{
						width: `calc(100% - ${drawerWidth}px)`,
						ml: `${drawerWidth}px`,
					}}
				>
					<Toolbar>
						{/* Search */}
						<SearchBox onSubmit={handleSearchBoxSubmit} />
					</Toolbar>
				</AppBar>
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						"& .MuiDrawer-paper": {
							width: drawerWidth,
							boxSizing: "border-box",
						},
					}}
					variant="permanent"
					anchor="left"
				>
					<Toolbar>
						<img
							src={LOGO1}
							width="50"
							height="50"
							alt="G4N"
						/>
						<img
							src={LOGO2}
							height="40"
							style={{ marginLeft: 20 }}
							alt="GitHub for Nature"
						/>
					</Toolbar>
					<Divider />

					{/* Facets */}
					{orderedFacets && (
						<Facets items={orderedFacets} onSectionChange={handleFacetChange} />
					)}
				</Drawer>
				<Box
					component="main"
					sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
				>
					<Toolbar />
					{/* Spinner */}
					{isLoggedIn ? (
						isLoading ? (
							<CircularProgress color="secondary" />
						) : (
							<h3>{`Found ${searchResults.length} Datasets`}</h3>
						)
					) : null}

					{/* Popup */}
					<DataSetDialog
						open={dialogData}
						data={dialogData}
						onClose={() => setDialogData(null)}
					/>

					{/* Login */}
					{!isLoggedIn && <LoginDialog onSubmit={onPasswordSubmit} />}

					{/* Results */}
					<VirtualizedList
						ref={listRef}
						totalCount={searchResults.length}
						itemContent={(index) => (
							<DataSetCard
								dataset={searchResults[index]}
								onClick={onDataSetClick}
							/>
						)}
						style={{ height: "1000px" }}
					/>
				</Box>
			</Box>
		</>
	);
}

// Returns { sectionA: [item1, ...], ... }
function getChecked(facets) {
	if (!facets) {
		return {};
	}
	const checkedItems = {};

	for (const sectionName of FACETS) {
		const checkedItemsInSection = Object.entries(facets[sectionName])
			.filter(([_, selected]) => selected)
			.map(([item, _]) => item);

		if (checkedItemsInSection.length) {
			checkedItems[sectionName] = checkedItemsInSection;
		}
	}

	return checkedItems;
}

export default App;
