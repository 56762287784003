import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	IconButton,
	Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function DataSetDialog({ onClose, open, data }) {
	const handleClose = () => {
		onClose();
	};

	return (
		data?.name && (
			<Dialog onClose={handleClose} open={open}>
				<DialogTitle
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					{data.name}
					<IconButton
						aria-label="Close"
						onClick={handleClose}
						sx={{ ml: "auto", color: "grey.500" }}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<Divider />
				<DialogContent>
					{/* Dataset Fields */}
					{Object.entries(data).map(([key, value]) => (
						<DialogContentText key={key} sx={{ margin: "10px" }}>
							<span style={{ fontWeight: "bold" }}>{key}: </span>
							{value}
						</DialogContentText>
					))}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		)
	);
}

export default DataSetDialog;
